export enum StatusEmpresa{

     ATIVO = "ATIVO",
     BLOQUEADA = "BLOQUEADA",
     INATIVO = "INATIVO",
     SUSPENSA = "SUSPENSA"

    
}

export class DescriptionStatusEmpresa{
    static readonly ATIVO = new DescriptionStatusEmpresa(StatusEmpresa.ATIVO, 'Ativo', 'badge-light-success');
    static readonly BLOQUEADA = new DescriptionStatusEmpresa(StatusEmpresa.BLOQUEADA, 'Bloqueada', 'badge-light-warning');
    static readonly INATIVO = new DescriptionStatusEmpresa(StatusEmpresa.INATIVO, 'Inativa', 'badge-light-danger');
    static readonly SUSPENSA = new DescriptionStatusEmpresa(StatusEmpresa.SUSPENSA, 'Suspensa','badge-light-secondary');
  
    
    static readonly values = [DescriptionStatusEmpresa.ATIVO, DescriptionStatusEmpresa.BLOQUEADA, DescriptionStatusEmpresa.INATIVO, DescriptionStatusEmpresa.SUSPENSA];
  
    private constructor(public readonly id: StatusEmpresa, public readonly descricao: string, public readonly badge: string) {}
  
    
    static getById(id: StatusEmpresa): DescriptionStatusEmpresa | undefined {
      return DescriptionStatusEmpresa.values.find(status => status.id === id);
    }
}