import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
  // Dashboard
  {
    id: 'dashboard',
    title: 'Dashboard',
    translate: 'MENU.DASHBOARD.COLLAPSIBLE',
    type: 'collapsible',
    // role: ['Admin'], //? To hide collapsible based on user role
    icon: 'menu',
    badge: {
      title: '2',
      translate: 'MENU.DASHBOARD.BADGE',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'analytics',
        title: 'Analytics',
        translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'circle',
        url: 'dashboard/analytics'
      },
      {
        // If role is not assigned will be display to all
        id: 'prevenda',
        title: 'prevenda',
        translate: 'MENU.DASHBOARD.prevenda',
        type: 'item',
        icon: 'circle',
        url: 'pages/financeiro/pre-venda'
      }
    ]
  },
  // Apps & Pages
  {
    id: 'apps',
    type: 'section',
    title: 'Apps & Pages',
    translate: 'MENU.APPS.SECTION',
    icon: 'package',
    children: [
      {
        id: 'pages',
        title: 'Cadastro',
        translate: 'MENU.PAGES.SECTION',
        type: 'collapsible',
        icon: 'folder',
        children: [
           //{
            //id: 'authentication',
            //title: 'Authentication',
            ///translate: 'MENU.PAGES.AUTH.COLLAPSIBLE',
            //type: 'collapsible',
            //icon: 'circle',
            //children: [
              // {
              //   id: 'login-v1',
              //   title: 'Login V1',
              //   translate: 'MENU.PAGES.AUTH.LOGIN1',
              //   type: 'item',
              //   url: 'pages/authentication/login-v1',
              //   openInNewTab: true
              // },
              // {
              //   id: 'login-v2',
              //   title: 'Login V2',
              //   translate: 'MENU.PAGES.AUTH.LOGIN2',
              //   type: 'item',
              //   url: 'pages/authentication/login-v2',
              //   openInNewTab: true
              // },
              // {
              //   id: 'register-v1',
              //   title: 'Register V1',
              //   translate: 'MENU.PAGES.AUTH.REGISTER1',
              //   type: 'item',
              //   url: 'pages/authentication/register-v1',
              //   openInNewTab: true
              // },
              // {
              //   id: 'register-v2',
              //   title: 'Register V2',
              //   translate: 'MENU.PAGES.AUTH.REGISTER2',
              //   type: 'item',
              //   url: 'pages/authentication/register-v2',
              //   openInNewTab: true
              // },
              // {
              //   id: 'forgot-password-v1',
              //   title: 'Forgot Password V1',
              //   translate: 'MENU.PAGES.AUTH.FORGOTPASSWORD1',
              //   type: 'item',
              //   url: 'pages/authentication/forgot-password-v1',
              //   openInNewTab: true
              // },
              // {
              //   id: 'forgot-password-v2',
              //   title: 'Forgot Password V2',
              //   translate: 'MENU.PAGES.AUTH.FORGOTPASSWORD2',
              //   type: 'item',
              //   url: 'pages/authentication/forgot-password-v2',
              //   openInNewTab: true
              // },
              // {
              //   id: 'reset-password-v1',
              //   title: 'Reset Password V1',
              //   translate: 'MENU.PAGES.AUTH.RESETPASSWORD1',
              //   type: 'item',
              //   url: 'pages/authentication/reset-password-v1',
              //   openInNewTab: true
              // }
              //,
              // {
              //   id: 'reset-password-v2',
              //   title: 'Reset Password V2',
              //   translate: 'MENU.PAGES.AUTH.RESETPASSWORD2',
              //   type: 'item',
              //   url: 'pages/authentication/reset-password-v2',
              //   openInNewTab: true
              // }
            //]
         // },
          {
            id: 'account-settings',
            title: 'Usuários',
            translate: 'MENU.PAGES.ACCOUNTSETTINGS',
            type: 'item',
            icon: 'users',
            //url: 'pages/account-settings'
            url: 'cadastro/login'
          },
          {
            id: 'roles',
            title: 'Roles',
            translate: 'MENU.PAGES.ROLES',
            type: 'item',
            icon: 'circle',
            //url: 'pages/account-settings'
            url: 'cadastro/roles'
          },
          {
            id: 'prefeitura',
            title: 'Prefeituras',
            translate: 'MENU.PAGES.PREFEITURA',
            type: 'item',
            icon: 'circle',
            url: 'cadastro/prefeitura'
          },
          {
            id: 'empresa',
            title: 'Empresas',
            translate: 'MENU.PAGES.EMPRESA',
            type: 'item',
            icon: 'circle',
            url: 'cadastro/empresas'
          },
          {
            id: 'perfil',
            title: 'Perfil',
            translate: 'MENU.PAGES.PERFIL',
            type: 'item',
            icon: 'circle',
            url: 'cadastro/perfil'
          },
          {
            id: 'cliente',
            title: 'Clientes',
            translate: 'MENU.PAGES.CLIENTE',
            type: 'item',
            icon: 'circle',
            url: 'cadastro/cliente'
          },
          // {
          //   id: 'menu',
          //   title: 'Menu',
          //   translate: 'MENU.PAGES.ACCOUNTSETTINGS',
          //   type: 'item',
          //   icon: 'circle',
          //   //url: 'pages/account-settings'
          //   url: 'pages/cadastro/menu'
          // },
          // {
          //   id: 'profile',
          //   title: 'Profile',
          //   translate: 'MENU.PAGES.PROFILE',
          //   type: 'item',
          //   icon: 'circle',
          //   url: 'pages/cadastro/cadastro-empresas'
          //   // collapsed: true
          // },
          // {
          //   id: 'faq',
          //   title: 'FAQ',
          //   translate: 'MENU.PAGES.FAQ',
          //   type: 'item',
          //   icon: 'circle',
          //   url: 'pages/cadastro/cadastro-clientes'
          // },
          {
            id: 'knowledgeBase',
            title: 'Knowledge Base',
            translate: 'MENU.PAGES.KB',
            type: 'item',
            icon: 'circle',
            url: 'pages/cadastro/cadastro-clientes-simplificado'
          },
          {
            id: 'pricing',
            title: 'Pricing',
            translate: 'MENU.PAGES.PRICING',
            type: 'item',
            icon: 'circle',
            url: 'pages/cadastro/cadastro-veiculos'
          },
          {
            id: 'GC',
            title: 'GC',
            translate: 'MENU.PAGES.GC',
            type: 'item',
            icon: 'circle',
            url: 'pages/cadastro/cadastro-guia-condutor'
          },
          {
            id: 'atividades',
            title: 'Atividade',
            translate: 'MENU.PAGES.CP',
            type: 'item',
            icon: 'circle',
            url: 'cadastro/atividades'
          },
          {
            id: 'MP',
            title: 'MP',
            translate: 'MENU.PAGES.MP',
            type: 'item',
            icon: 'circle',
            url: 'pages/cadastro/cadastro-motorista-piloto'
          },
          {
            id: 'TRIP',
            title: 'TRIP',
            translate: 'MENU.PAGES.TRIP',
            type: 'item',
            icon: 'circle',
            url: 'pages/cadastro/cadastro-tripulantes'
          },
          {
            id: 'MORAD',
            title: 'MORAD',
            translate: 'MENU.PAGES.MORAD',
            type: 'item',
            icon: 'circle',
            url: 'pages/cadastro/cadastro-moradores'
          },
          {
            id: 'TEMP',
            title: 'TEMP',
            translate: 'MENU.PAGES.TEMP',
            type: 'item',
            icon: 'circle',
            url: 'pages/cadastro/cadastro-temporada'
          },
          {
            id: 'HORARIO',
            title: 'HORARIO',
            translate: 'MENU.PAGES.HORARIO',
            type: 'item',
            icon: 'circle',
            url: 'pages/cadastro/cadastro-horarios'
          },
          {
            id: 'DISP',
            title: 'DISP',
            translate: 'MENU.PAGES.DISP',
            type: 'item',
            icon: 'circle',
            url: 'pages/cadastro/consultar-disponibilidade-vagas'
          },
          {
            id: 'HOSP',
            title: 'HOSP',
            translate: 'MENU.PAGES.HOSP',
            type: 'item',
            icon: 'circle',
            url: 'pages/cadastro/cadastro-hospedagem'
          }

          // {
          //   id: 'blog',
          //   title: 'Blog',
          //   translate: 'MENU.PAGES.BLOG.COLLAPSIBLE',
          //   type: 'collapsible',
          //   icon: 'circle',
          //   children: [
          //     {
          //       id: 'blog-list',
          //       title: 'List',
          //       translate: 'MENU.PAGES.BLOG.LIST',
          //       type: 'item',
          //       url: 'pages/blog-list'
          //     },
          //     {
          //       id: 'blog-details',
          //       title: 'Detail',
          //       translate: 'MENU.PAGES.BLOG.DETAILS',
          //       type: 'item',
          //       url: 'pages/blog-details'
          //     },
          //     {
          //       id: 'blog-edit',
          //       title: 'Edit',
          //       translate: 'MENU.PAGES.BLOG.EDIT',
          //       type: 'item',
          //       url: 'pages/blog-edit'
          //     }
          //   ]
           //},

          // {
          //   id: 'mail-template',
          //   title: 'Mail Template',
          //   translate: 'MENU.PAGES.MAIL.COLLAPSIBLE',
          //   type: 'collapsible',
          //   icon: 'circle',
          //   children: [
          //     {
          //       id: 'mail-welcome',
          //       title: 'Welcome',
          //       translate: 'MENU.PAGES.MAIL.WELCOME',
          //       type: 'item',
          //       url: 'https://pixinvent.com/demo/vuexy-mail-template/mail-welcome.html',
          //       externalUrl: true,
          //       openInNewTab: true
          //     },
          //     {
          //       id: 'mail-reset',
          //       title: 'Reset Password',
          //       translate: 'MENU.PAGES.MAIL.RESET',
          //       type: 'item',
          //       url: 'https://pixinvent.com/demo/vuexy-mail-template/mail-reset-password.html',
          //       externalUrl: true,
          //       openInNewTab: true
          //     },
          //     {
          //       id: 'mail-verify',
          //       title: 'Verify',
          //       translate: 'MENU.PAGES.MAIL.VERIFY',
          //       type: 'item',
          //       url: 'https://pixinvent.com/demo/vuexy-mail-template/mail-verify-email.html',
          //       externalUrl: true,
          //       openInNewTab: true
          //     },
          //     {
          //       id: 'mail-deactivate',
          //       title: 'Deactivate',
          //       translate: 'MENU.PAGES.MAIL.DEACTIVATE',
          //       type: 'item',
          //       url: 'https://pixinvent.com/demo/vuexy-mail-template/mail-deactivate-account.html',
          //       externalUrl: true,
          //       openInNewTab: true
          //     },
          //     {
          //       id: 'mail-invoice',
          //       title: 'Invoice',
          //       translate: 'MENU.PAGES.MAIL.INVOICE',
          //       type: 'item',
          //       url: 'https://pixinvent.com/demo/vuexy-mail-template/mail-invoice.html',
          //       externalUrl: true,
          //       openInNewTab: true
          //     },
          //     {
          //       id: 'mail-promotional',
          //       title: 'Promotional',
          //       translate: 'MENU.PAGES.MAIL.PROMOTIONAL',
          //       type: 'item',
          //       url: 'https://pixinvent.com/demo/vuexy-mail-template/mail-promotional.html',
          //       externalUrl: true,
          //       openInNewTab: true
          //     }
          //   ]
          // },

          // {
          //   id: 'miscellaneous',
          //   title: 'Miscellaneous',
          //   translate: 'MENU.PAGES.MISC.COLLAPSIBLE',
          //   type: 'collapsible',
          //   icon: 'circle',
          //   children: [
          //     {
          //       id: 'misc-comingsoon',
          //       title: 'Coming Soon',
          //       translate: 'MENU.PAGES.MISC.COMINGSOON',
          //       type: 'item',
          //       url: 'pages/miscellaneous/coming-soon',
          //       openInNewTab: true
          //     },

          //     {
          //       id: 'misc-not-authorized',
          //       title: 'Not Authorized',
          //       translate: 'MENU.PAGES.MISC.NOTAUTH',
          //       type: 'item',
          //       url: 'pages/miscellaneous/not-authorized',
          //       openInNewTab: true
          //     },
          //     {
          //       id: 'maintenance',
          //       title: 'Maintenance',
          //       translate: 'MENU.PAGES.MISC.MAINTENANCE',
          //       type: 'item',
          //       url: 'pages/miscellaneous/maintenance',
          //       openInNewTab: true
          //     },
          //     {
          //       id: 'error',
          //       title: 'Error',
          //       translate: 'MENU.PAGES.MISC.ERROR',
          //       type: 'item',
          //       url: 'pages/miscellaneous/error',
          //       openInNewTab: true
          //     }
            //]
         // }
        ]
      },
      {
        id: 'invoice',
        title: 'Invoice',
        translate: 'MENU.APPS.INVOICE.COLLAPSIBLE',
        type: 'collapsible',
        icon: 'file-text',
        children: [
          {
            id: 'invoice-list',
            title: 'List',
            translate: 'MENU.APPS.INVOICE.LIST',
            type: 'item',
            icon: 'circle',
            url: 'pages/financeiro/pre-venda'
          },
          {
            id: 'invoicePreview',
            title: 'Preview',
            translate: 'MENU.APPS.INVOICE.PREVIEW',
            type: 'item',
            icon: 'circle',
            //url: 'apps/invoice/preview'
            url: 'pages/financeiro/gerar-dam'
          },
          {
            id: 'invoiceEdit',
            title: 'Edit',
            translate: 'MENU.APPS.INVOICE.EDIT',
            type: 'item',
            icon: 'circle',
            //url: 'apps/invoice/edit'
            url: 'pages/financeiro/realizar-recarga'
          },
          {
            id: 'invoiceAdd',
            title: 'Add',
            translate: 'MENU.APPS.INVOICE.ADD',
            type: 'item',
            icon: 'circle',
            //url: 'apps/invoice/add'
          url: 'pages/financeiro/movimentacao-credito'
          },
          {
            id: 'RECARGA',
            title: 'RECARGA',
            translate: 'MENU.APPS.INVOICE.RECARGA',
            type: 'item',
            icon: 'circle',
            //url: 'apps/invoice/add'
          url: 'pages/financeiro/recarga-agencia'
          },
          {
            id: 'RECOLH',
            title: 'RECOLH',
            translate: 'MENU.APPS.INVOICE.RECOLH',
            type: 'item',
            icon: 'circle',
            //url: 'apps/invoice/add'
          url: 'pages/financeiro/recolhimento-taxa'
          }
        ]
      },
      {
        id: 'e-commerce',
        title: 'eCommerce',
        translate: 'MENU.APPS.ECOMMERCE.COLLAPSIBLE',
        type: 'collapsible',
        icon: 'calendar',
        children: [
          {
            id: 'shop',
            title: 'Shop',
            translate: 'MENU.APPS.ECOMMERCE.SHOP',
            type: 'item',
            icon: 'circle',
            //url: 'apps/e-commerce/shop'
            url: 'pages/gerenciamento/check-point'
          },
          {
            id: 'details',
            title: 'Details',
            translate: 'MENU.APPS.ECOMMERCE.DETAIL',
            type: 'item',
            icon: 'circle',
            //url: 'apps/e-commerce/details'
            url: 'pages/gerenciamento/passeios'
          },
          {
            id: 'wishList',
            title: 'Wish List',
            translate: 'MENU.APPS.ECOMMERCE.WISHLIST',
            type: 'item',
            icon: 'circle',
            //url: 'apps/e-commerce/wishlist'
            url: 'pages/gerenciamento/autorizacao-transito'
          },
          {
            id: 'checkout',
            title: 'Checkout',
            translate: 'MENU.APPS.ECOMMERCE.CHECKOUT',
            type: 'item',
            icon: 'circle',
            //url: 'apps/e-commerce/checkout'
            url: 'pages/gerenciamento/solicitacao-veiculos'
          },
          {
            id: 'EMITIRNFSE',
            title: 'EMITIRNFSE',
            translate: 'MENU.APPS.ECOMMERCE.EMITIRNFSE',
            type: 'item',
            icon: 'circle',
            //url: 'apps/e-commerce/checkout'
            url: 'pages/gerenciamento/emitir-nfse'
          },
          {
            id: 'NFSEEMITIDAS',
            title: 'NFSEEMITIDAS',
            translate: 'MENU.APPS.ECOMMERCE.NFSEEMITIDAS',
            type: 'item',
            icon: 'circle',
            //url: 'apps/e-commerce/checkout'
            url: 'pages/gerenciamento/nfse-emitidas'
          },
          {
            id: 'PORTALTRANSP',
            title: 'PORTALTRANSP',
            translate: 'MENU.APPS.ECOMMERCE.PORTALTRANSP',
            type: 'item',
            icon: 'circle',
            //url: 'apps/e-commerce/checkout'
            url: 'pages/gerenciamento/portal-transparencia'
          },
          {
            id: 'EMBARC',
            title: 'EMBARC',
            translate: 'MENU.APPS.ECOMMERCE.EMBARC',
            type: 'item',
            icon: 'circle',
            //url: 'apps/e-commerce/checkout'
            url: 'pages/gerenciamento/embarcacoes'
          },
          {
            id: 'MOVHOSP',
            title: 'MOVHOSP',
            translate: 'MENU.APPS.ECOMMERCE.MOVHOSP',
            type: 'item',
            icon: 'circle',
            //url: 'apps/e-commerce/checkout'
            url: 'pages/gerenciamento/movimentacao-hospedagem'
          },
          {
            id: 'APROVA',
            title: 'APROVA',
            translate: 'MENU.APPS.ECOMMERCE.APROVA',
            type: 'item',
            icon: 'circle',
            //url: 'apps/e-commerce/checkout'
            url: 'pages/gerenciamento/aprovacao'
          },
          {
            id: 'HORARIOS',
            title: 'HORARIOS',
            translate: 'MENU.APPS.ECOMMERCE.HORARIOS',
            type: 'item',
            icon: 'circle',
            //url: 'apps/e-commerce/checkout'
            url: 'pages/gerenciamento/horarios'
          },
          {
            id: 'RESERVAS',
            title: 'RESERVAS',
            translate: 'MENU.APPS.ECOMMERCE.RESERVAS',
            type: 'item',
            icon: 'circle',
            //url: 'apps/e-commerce/checkout'
            url: 'pages/gerenciamento/reservas'
          }
        ]
      },
      {
        id: 'users',
        title: 'User',
        translate: 'MENU.APPS.USER.COLLAPSIBLE',
        type: 'collapsible',
        icon: 'user',
        children: [
          {
            id: 'list',
            title: 'List',
            translate: 'MENU.APPS.USER.LIST',
            type: 'item',
            icon: 'circle',
            //url: 'apps/user/user-list'
            url: 'pages/configuracoes/prefeitura'
          },
          {
            id: 'view',
            title: 'View',
            translate: 'MENU.APPS.USER.VIEW',
            type: 'item',
            icon: 'circle',
            //url: 'apps/user/user-view'
            url: 'pages/configuracoes/mudar-senha'
          },
          {
            id: 'edit',
            title: 'Edit',
            translate: 'MENU.APPS.USER.EDIT',
            type: 'item',
            icon: 'circle',
            //url: 'apps/user/user-edit'
            url: 'pages/configuracoes/parametros'
          },
          {
            id: 'GERAIS',
            title: 'GERAIS',
            translate: 'MENU.APPS.USER.GERAIS',
            type: 'item',
            icon: 'circle',
            //url: 'apps/user/user-edit'
            url: 'pages/configuracoes/gerais'
          }
        ]
      },
      {
        id: 'cards',
        title: 'Cards',
        translate: 'MENU.UI.CARDS.COLLAPSIBLE',
        type: 'collapsible',
        icon: 'bar-chart-2',
        badge: {
          title: 'New',
          translate: 'MENU.UI.CARDS.BADGE',
          classes: 'badge-light-success badge-pill'
        },
        children: [
          {
            id: 'card-basic',
            title: 'Basic',
            translate: 'MENU.UI.CARDS.BASIC',
            type: 'item',
            icon: 'circle',
            //url: 'ui/card/card-basic'
            url: 'pages/relatorios/empresas'
          },
          // {
          //   id: 'card-advance',
          //   title: 'Advance',
          //   translate: 'MENU.UI.CARDS.ADVANCE',
          //   type: 'item',
          //   icon: 'circle',
          //   //url: 'ui/card/advance'
          //   url: 'pages/relatorios/lista'
          // },
       
          {
            id: 'lista',
            title: 'LISTA',
            translate: 'MENU.UI.CARDS.LISTA.COLLAPSIBLE',
            type: 'collapsible',
            icon: 'circle',
            children: [
              {
                id: 'atrativo',
                title: 'atrativo',
                translate: 'MENU.UI.CARDS.LISTA.ATRATIVO',
                type: 'item',
                url: 'pages/relatorios/lista-atrativos'
              },
              {
                id: 'motoristas',
                title: 'motoristas',
                translate: 'MENU.UI.CARDS.LISTA.MOTORISTAS',
                type: 'item',
                url: 'pages/relatorios/lista-motoristas'
              },
              {
                id: 'veiculos',
                title: 'veiculos',
                translate: 'MENU.UI.CARDS.LISTA.VEICULOS',
                type: 'item',
                url: 'pages/relatorios/lista-veiculos'
              },
              {
                id: 'guias',
                title: 'guias',
                translate: 'MENU.UI.CARDS.LISTA.GUIAS',
                type: 'item',
                url: 'pages/relatorios/lista-guias'
              },
              {
                id: 'empresas',
                title: 'empresas',
                translate: 'MENU.UI.CARDS.LISTA.EMPRESAS',
                type: 'item',
                url: 'pages/relatorios/lista-empresas'
              }
            ]
          },







          {
            id: 'card-statistics',
            title: 'Statistics',
            translate: 'MENU.UI.CARDS.STATISTICS',
            type: 'item',
            icon: 'circle',
            //url: 'ui/card/statistics'
            url: 'pages/relatorios/fluxo-de-venda'
          },
          {
            id: 'Card-analytics',
            title: 'Analytics',
            translate: 'MENU.UI.CARDS.ANALYTICS',
            type: 'item',
            icon: 'circle',
            //url: 'ui/card/analytics'
            url: 'pages/relatorios/capacidade-de-cargas'
          },
          {
            id: 'card-actions',
            title: 'Actions',
            translate: 'MENU.UI.CARDS.ACTIONS',
            type: 'item',
            icon: 'circle',
            //url: 'ui/card/actions'
            url: 'pages/relatorios/lista-de-portais'
          },
          {
            id: 'vouche',
            title: 'vouche',
            translate: 'MENU.UI.CARDS.vouche',
            type: 'item',
            icon: 'circle',
            //url: 'ui/card/actions'
            url: 'pages/relatorios/vouches'
          },
          {
            id: 'Fluxoatrativo',
            title: 'Fluxoatrativo',
            translate: 'MENU.UI.CARDS.Fluxoatrativo',
            type: 'item',
            icon: 'circle',
            //url: 'ui/card/actions'
            url: 'pages/relatorios/fluxo-venda-atrativo'
          },
          {
            id: 'guias',
            title: 'guias',
            translate: 'MENU.UI.CARDS.guias',
            type: 'item',
            icon: 'circle',
            //url: 'ui/card/actions'
            url: 'pages/relatorios/fluxo-guias'
          },
          {
            id: 'motoristas',
            title: 'motoristas',
            translate: 'MENU.UI.CARDS.motoristas',
            type: 'item',
            icon: 'circle',
            //url: 'ui/card/actions'
            url: 'pages/relatorios/fluxo-motoristas'
          },
          {
            id: 'hospedagem',
            title: 'hospedagem',
            translate: 'MENU.UI.CARDS.hospedagem',
            type: 'item',
            icon: 'circle',
            //url: 'ui/card/actions'
            url: 'pages/relatorios/fluxo-hospedagem'
          },
          {
            id: 'transito',
            title: 'transito',
            translate: 'MENU.UI.CARDS.transito',
            type: 'item',
            icon: 'circle',
            //url: 'ui/card/actions'
            url: 'pages/relatorios/transito'
          },///
          {
            id: 'visitantes',
            title: 'visitantes',
            translate: 'MENU.UI.CARDS.visitantes',
            type: 'item',
            icon: 'circle',
            //url: 'ui/card/actions'
            url: 'pages/relatorios/visitantes'
          },
          {
            id: 'fluxogerencial',
            title: 'fluxogerencial',
            translate: 'MENU.UI.CARDS.fluxogerencial',
            type: 'item',
            icon: 'circle',
            //url: 'ui/card/actions'
            url: 'pages/relatorios/fluxo-de-venda-gerencial'
          },
          {
            id: 'agendamentos',
            title: 'agendamentos',
            translate: 'MENU.UI.CARDS.agendamentos',
            type: 'item',
            icon: 'circle',
            //url: 'ui/card/actions'
            url: 'pages/relatorios/agendamentos'
          },
          {
            id: 'compartilhamento',
            title: 'compartilhamento',
            translate: 'MENU.UI.CARDS.compartilhamento',
            type: 'item',
            icon: 'circle',
            //url: 'ui/card/actions'
            url: 'pages/relatorios/compartilhamento'
          }
        ]
      }
    ]
  }
];
