import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'environments/environment';
import { User } from 'app/auth/models';
import { Usuario } from '../models/usuario';

@Injectable({ providedIn: 'root' })
export class UserService {
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {}

  /**
   * Get all users
   */
  getAll(options: { noPage?: boolean; filtro?: string; status?: string; page?: number, sizePage?: number, sorting?: string} = {}) {
    const { noPage = false, filtro, status, page , sizePage, sorting=null } = options;
    let URL = `${environment.apiUrl}${environment.authuser}/usuarios`
    let filtrosUrl: string[] = [];

    if (noPage) {
      filtrosUrl.push("noPage=true");
    }

    if (filtro && filtro.trim() !== '') {
      filtrosUrl.push(`userName=${filtro}`, `cpfCnpj=${filtro}`, `nome=${filtro}`,`email=${filtro}`, `perfil.nome=${filtro}`, `prefeitura.nomeFantasia=${filtro}`, `empresa.nomeFantasia=${filtro}`);
    }

    if(status && status.trim() !== ''){
      filtrosUrl.push(`status=${status}`);
    }

    if(page && page >= 0){
      filtrosUrl.push(`page=${page}`);
    }
    if(sizePage && sizePage >= 0){
      filtrosUrl.push(`size=${sizePage}`);
    }
    if(sorting && sorting.trim() !== ''){
      filtrosUrl.push(`sort=${sorting}`);
    }
   
    

    if (filtrosUrl.length > 0) {
      // Começa com ? e adiciona os filtros com &
      URL += '?' + filtrosUrl.join('&');
    }


    return this._http.get<User[]>(URL);
  }

  /**
   * Get user by id
   */
  getById(id: string) {
    return this._http.get<Usuario>(`${environment.apiUrl}${environment.authuser}/usuarios/${id}`);
  }

  putAlterPassword(data: any, usuarioId: string){
    
    const URL = `${environment.apiUrl}${environment.authuser}/usuarios/${usuarioId}/password`;
    const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    });
    return this._http.put(URL, JSON.stringify(data), { headers, responseType: 'text'});  
  
  }

  putAlter(data: any, usuarioId: string){
    
    const URL = `${environment.apiUrl}${environment.authuser}/usuarios/${usuarioId}`;
    const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    });
    return this._http.put(URL, JSON.stringify(data), { headers, observe: 'response'});  
  
  }
}
