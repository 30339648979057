import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Empresa } from 'app/auth/models';

@Injectable({ providedIn: 'root' })
export class EmpresaService {
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {}

  /**
   * Get all users
   */

  getAll(options: { noPage?: boolean; prefeituraId?: string, filtro?: string } = {}) {
    const { noPage = false, prefeituraId, filtro } = options;

    let url = `${environment.apiUrl}${environment.cadastro}/empresas`
    let filtrosUrl: string[] = [];

    if (noPage) {
      filtrosUrl.push("noPage=true");
    }


    if(prefeituraId && prefeituraId.trim() !== ''){
      filtrosUrl.push(`prefeituraId=${prefeituraId}`);
    }else{
      filtrosUrl.push(`prefeituraId=null`);
    }

    if (filtro && filtro.trim() !== '') {
      filtrosUrl.push(`nomeFantasia=${filtro}`, `cnpj=${filtro}`);
    }
  
    
    if (filtrosUrl.length > 0) {    
      url += '?' + filtrosUrl.join('&');
    }

    return this._http.get<Empresa[]>(url);
  }
  
  getById(id: number) {
    return this._http.get<Empresa>(`${environment.apiUrl}/empresas/${id}`);
  }
}
