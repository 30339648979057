import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {
  transform(fullName: string): any {

    if (!fullName) return '';
  
  const names = fullName.split(' ');  
  if (names.length === 1) {
    return names[0][0];
  }    
  return names[0][0] + names[names.length - 1][0];

    // return fullName
    //   ?.split(' ')
    //   .map(n => n[0])
    //   .join('');
  }
}
