
import { DescriptionStatusEmpresa, StatusEmpresa } from './status-empresa';

export class Empresa {
  prefeitura: { id: string };
  tipoEmpresaId: { id: string };
  prefeituraId: string;
  enquadramentoId: string;
  empresaId: string;
  municipioId: string;
  estadoId: string;
  email: string;
  razaoSocial: string;
  nomeFantasia: string;
  inscricaoMunicipal: string;
  cnpj: string;
  cep: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  telefone: string;
  dataCriacao: string;
  dataAtualizacao: string;
  status: StatusEmpresa;


  getStatusDetalhes():  DescriptionStatusEmpresa | undefined{
    //return "Teste";
    //return this.status ? DescriptionStatusEmpresa.getById(this.status) : { descricao: "Status não definido" };
    return DescriptionStatusEmpresa.getById(this.status);
  }
  
}
