import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role, TipoUsuario } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) {

    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.tipoUsuario === TipoUsuario.ADMINISTRADOR;
  }

  /**
   *  Confirms if user is client
   */
  get isPrefeitura() {
    return this.currentUser && this.currentUserSubject.value.tipoUsuario === TipoUsuario.PREFEITURA;
  }

  get isEmpresa() {
    return this.currentUser && this.currentUserSubject.value.tipoUsuario === TipoUsuario.EMPRESA;
  }
  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    let URL = `${environment.apiUrl}${environment.authuser}/auth/login`;
    console.info(URL);
    return this._http
      .post<any>(URL, { email, password })
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            const token = user.token;
            const type = user.type;

            if (token) {
              const payload = JSON.parse(atob(token.split('.')[1]));
              user.sub = payload.sub;
              user.tipoUsuario = payload.dataUser.tipo;
              user.roles = payload.roles;              
              user.user = payload.dataUser;
              user.iat = payload.iat;
              user.exp = payload.exp;
              localStorage.setItem("currentUser", JSON.stringify(user));
            } else {
              console.error('Token not found in auth');
            }

            /*localStorage.setItem('currentUser', JSON.stringify(user));
            const currentUser = JSON.parse(localStorage.getItem('currentUser'));
            const token = currentUser ? currentUser.token : null;
            if (token) {
              const tokenPayload = JSON.parse(atob(token.split('.')[1]));
              console.log(tokenPayload);
              user.user = tokenPayload;
              localStorage.setItem("currentUser",  JSON.stringify(user));

            } else {
              console.error('Token not found in local storage');
            }*/
            // Display welcome toast!

            setTimeout(() => {
              this._toastrService.success(
                'Você fez login com sucesso como um ' +
                user.tipoUsuario +
                ' para o Turis. Agora você pode começar a explorar. Aproveitar! 🎉',
                '👋 Bem vindo, ' + this.getFirstName(user.user.nome) + '!',
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }, 2500);

            // notify
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }

  getLastName(name: string): string {

    if (name && name.trim() !== '') {
      const partesNome = name.trim().split(' ');
      return partesNome[partesNome.length - 1];
    } else {
      return '';
    }
  }

  getFirstName(name: string): string {

    if (name && name.trim() !== '') {
      const partesNome = name.trim().split(' ');
      return partesNome[0];
    } else {
      return '';
    }
  }
}
