import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telefone'
})
export class TelefonePipe implements PipeTransform {

  transform(value: string | number): string {
    if (!value) return '';
    
    const rawValue = value.toString().replace(/\D/g, '');

    if (rawValue.length === 11) {
      return `(${rawValue.substring(0, 2)}) ${rawValue.substring(2, 3)} ${rawValue.substring(3, 7)}-${rawValue.substring(7)}`;
    }

    if (rawValue.length === 10) {
      return `(${rawValue.substring(0, 2)}) ${rawValue.substring(2, 6)}-${rawValue.substring(6)}`;
    }
    
    return value.toString();
  }

}
