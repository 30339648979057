import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Perfil, TipoUsuario } from 'app/auth/models';

@Injectable({ providedIn: 'root' })
export class PerfilService {
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {}

  /**
   * Get all users
   */
  getAll(options: { noPage?: boolean;tipoUsuario?:TipoUsuario; prefeituraId?: string, empresaId?:string, filtro?:string } = {}) {
    const { noPage = false, tipoUsuario, prefeituraId, empresaId, filtro} = options;
    let URL = `${environment.apiUrl}${environment.authuser}/perfis`
    let filtrosUrl: string[] = [];

    if (noPage) {
      filtrosUrl.push("noPage=true");
    }

    if(prefeituraId === undefined || prefeituraId === null || prefeituraId.trim() === ''){
      filtrosUrl.push("prefeituraId=null");
    }else{
      filtrosUrl.push(`prefeituraId=${prefeituraId}`);
    }
    if(empresaId === undefined || empresaId === null || empresaId.trim() === ''){
      filtrosUrl.push("empresaId=null");
    }else{
      filtrosUrl.push(`empresaId=${empresaId}`);
    }   

    if (filtro && filtro.trim() !== '') {
      filtrosUrl.push(`nome=${filtro}`);
    }
  
    // Constrói a URL com base nos filtros adicionados
    if (filtrosUrl.length > 0) {
      // Começa com ? e adiciona os filtros com &
      URL += '?' + filtrosUrl.join('&');
    }

    return this._http.get<Perfil[]>(URL);
  }
  
}
