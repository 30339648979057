
import { StatusEmpresa } from './status-empresa';

export class Empresa {
  empresaId: string;
  razaoSocial: string;
  nomeFantasia: string;
  inscricaoMunicipal: string;
  cnpj: string;
  cep: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  telefone: string;
  dataCriacao: string;
  dataAtualizacao: string;
  status: StatusEmpresa;
  
}
