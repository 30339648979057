import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Empresa } from 'app/auth/models';
import { StatusEmpresa } from '../models/status-empresa';
import { map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EmpresaService implements Resolve<any>{
  rows: any;
  onSettingsChanged: BehaviorSubject<any>;
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {
    this.onSettingsChanged = new BehaviorSubject({});
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDataTableRows()]).then(() => {
        resolve();
      }, reject);
    });
  }

  getDataTableRows(options: { filtro?: string; status?: string; page?: number, sizePage?: number, sorting?: string} = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAll(options).subscribe((response: any) => {
        this.rows = response;
        this.onSettingsChanged.next(this.rows);
        resolve(this.rows);
      }, reject);
    });
  }


  /**
   * Get all users
   */

  getAll(options: { noPage?: boolean; prefeituraId?: string, filtro?: string, tipoEmpresaId?: string,  status?: string; } = {}) {
    const { noPage = false, prefeituraId, filtro, tipoEmpresaId, status } = options;

    let url = `${environment.apiUrl}${environment.cadastro}/empresas`
    let filtrosUrl: string[] = [];

    if (noPage) {
      filtrosUrl.push("noPage=true");
    }


    if(prefeituraId && prefeituraId.trim() !== ''){
      filtrosUrl.push(`prefeituraId=${prefeituraId}`);
    }else{
      filtrosUrl.push(`prefeituraId=null`);
    }

    if (filtro && filtro.trim() !== '') {
      filtrosUrl.push(`nomeFantasia=${filtro}`, `cnpj=${filtro}`);
    }

    if(tipoEmpresaId && tipoEmpresaId !==''){
      filtrosUrl.push(`tipoEmpresa.tipoEmpresaId=${tipoEmpresaId}`);
    }
    
    if(status && status.trim() !== ''){
      filtrosUrl.push(`status=${status}`);
    }
    
    if (filtrosUrl.length > 0) {    
      url += '?' + filtrosUrl.join('&');
    }

   return this._http.get<any>(url);
   
  }
  
  getById(id: string) {
    return this._http.get<Empresa>(`${environment.apiUrl}${environment.cadastro}/empresas/${id}`);
  }

  save(data: any){
    
    console.info(JSON.stringify(data));
    const URL = `${environment.apiUrl}${environment.cadastro}/empresas`;
    const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    });
    return this._http.post<any>(URL, JSON.stringify(data), { headers, observe: 'response' });  
  }

  update(empresaId: string, empresaPayload: any): Observable<any> {  
    console.info(JSON.stringify(empresaPayload));
    const URL = `${environment.apiUrl}${environment.cadastro}/empresas/${empresaId}`;
    const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    });
    return this._http.put(URL, JSON.stringify(empresaPayload), { headers, observe: 'response'}); 
  }
}
