import { Role } from './role';
import { TipoUsuario } from './tipo-usuario';
import { UserDetails } from './user-details';
export class User {
  token?: string;
  type?: string;
  sub?: string;
  //roles: string;
  tipoUsuario?: TipoUsuario;
  id?: number;
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  roles?: Role;  
  user?: UserDetails;
  iat?: number;
  exp?: number;
}
