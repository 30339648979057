import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'core-touchspin',
  templateUrl: './core-touchspin.component.html',
  styleUrls: ['./core-touchspin.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreTouchspinComponent),
      multi: true,
    },
  ],
})
export class CoreTouchspinComponent implements ControlValueAccessor, OnInit {
  @Input() numberValue: number | null = 0; 
  @Input() iconChevron = false;
  @Input() disabledValue = false;
  @Input() size: string = '';
  @Input() color: string = '';
  @Input() stepValue: number = 1;
  @Input() preventSubmit: boolean = true;
  @Input() maxValue: number = 9999;
  @Input() minValue: number = 0;

  @Output() onChange = new EventEmitter<number | null>();

  public onTouched: () => void = () => {};
  private onChangeFn: (value: number) => void = () => {};

  disabledValueIncrement = false;
  disabledValueDecrement = false;

  ngOnInit(): void {
    this.updateButtonStates();
  }

  // Métodos obrigatórios do ControlValueAccessor
  writeValue(value: number | null): void {
    if (value === null) {
      this.numberValue = null; // Permite que o campo seja vazio ou nulo
    } else if (value >= this.minValue && value <= this.maxValue) {
      this.numberValue = value; // Valor dentro dos limites
    } else {
      // Se o valor estiver fora do intervalo, ajusta para o valor dentro do intervalo
      this.numberValue = Math.min(Math.max(value, this.minValue), this.maxValue);
    }
    this.updateButtonStates();
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabledValue = isDisabled;
    this.updateButtonStates();
  }

  onInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    const parsedValue = parseInt(inputValue, 10);

    if (!isNaN(parsedValue)) {
      this.numberValue = Math.min(Math.max(parsedValue, this.minValue), this.maxValue);
      this.propagateChange();
    }
  }

  increment(event: Event): void {
    if (this.preventSubmit) event.preventDefault();
    this.numberValue = Math.min(this.numberValue + this.stepValue, this.maxValue);
    this.propagateChange();
  }

  decrement(event: Event): void {
    if (this.preventSubmit) event.preventDefault();
    this.numberValue = Math.max(this.numberValue - this.stepValue, this.minValue);
    this.propagateChange();
  }

  private propagateChange(): void {
    this.onChangeFn(this.numberValue); // Propaga para o Angular Forms
    this.onChange.emit(this.numberValue); // Emite o evento de mudança
    this.updateButtonStates();
  }

  private updateButtonStates(): void {
    this.disabledValueIncrement = this.numberValue >= this.maxValue;
    this.disabledValueDecrement = this.numberValue <= this.minValue;
  }
}
