import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { TipoUsuarioList } from '../models/tipo-usuario-list';

@Injectable({ providedIn: 'root' })
export class TipoUsuarioService {
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {}

  /**
   * Get all users
   */
  getAll(options: { noPage?: boolean; filtro?: string } = {}) {
    const { noPage = false, filtro } = options;
    let URL = `${environment.apiUrl}${environment.authuser}/tipos-usuario`   

    return this._http.get<TipoUsuarioList[]>(URL);
  }
  
}
